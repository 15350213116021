import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService, AuthService } from '@app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({opacity: 0, right: '-100%', position: 'absolute'}),
        animate('0.5s ease-out', style({opacity: 1, right: '0%'}))
      ]),
      transition(':leave', [
        style({opacity: 1, right: '0%', position: 'absolute'}),
        animate('0.5s ease-in', style({opacity: 0, right: '-100%'}))
      ]),
    ])
  ]
})
export class NavbarComponent implements OnInit {
  loggedIn = false;
  list: Subscription;
  dashboards;

  constructor(private auth: AuthService, private api: ApiService) { }

  ngOnInit() {
    this.auth.isLoggedIn$.subscribe(status => {
      this.loggedIn = status;

      if (status) {
        this.list = this.api.listDashboards$.subscribe(
          dashboards => this.dashboards = dashboards
        );
      } else {
        this.list?.unsubscribe();
        this.list = null;
      }
    });
  }

  onLogout() {
    this.auth.logout().subscribe();
  }
}
