import {Injectable} from '@angular/core';
import {CanActivate, CanLoad, Router} from '@angular/router';
import {AuthService} from './auth.service';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(protected auth: AuthService, protected router: Router) {}

  canActivate() { return this.loginGuard(); }
  canLoad() { return this.loginGuard(); }

  protected async loginGuard() {
    const isLoggedIn = await this.auth.isLoggedIn$
      .pipe(take(1)).toPromise();

    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    }

    return isLoggedIn;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(protected auth: AuthService, protected router: Router) {}

  async canActivate() {
    const isLoggedIn = await this.auth.isLoggedIn$
      .pipe(take(1)).toPromise();

    if (isLoggedIn) {
      this.router.navigate(['/dashboard']);
    }

    return !isLoggedIn;
  }
}
