import { Component, OnDestroy, OnInit } from '@angular/core';
import { Logger } from '../../services/logger/logger.service';
import { DomSanitizer } from '@angular/platform-browser';
import { animate, style, transition, trigger } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';

import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';

@Component({
  selector: 'app-notify-banner',
  templateUrl: './notify-banner.component.html',
  styleUrls: ['./notify-banner.component.scss'],
  animations: [
    trigger('show', [
      transition(':leave', [
        style({ zIndex: '0', overflow: 'hidden' }),
        animate('250ms ease', style({ height: '0',
          // use longhand property format to circumvent Firefox/Edge issue with animations
          paddingTop: '0', paddingBottom: '0', paddingLeft: '10px', paddingRight: '10px' }))
      ]),
    ])
  ]
})
export class NotifyBannerComponent implements OnInit, OnDestroy {
  messages: any = [];
  halfBanner = false;

  constructor(private sanitizer: DomSanitizer, private router: Router) {}

  ngOnInit() {
    // TODO: give identity to individual instances, return reference
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) { // clear out non-persistent notifications on navigation
        this.messages = pickBy(this.messages, item => item.persist);
        this.halfBanner = /registration|login|recovery/.test(event.url);
      }
    });

    // TODO: handle duplicates
    Logger.message.subscribe((message: any) => {
      // TODO: use class or interface to define properties, preferably similar to stream.Activity
      if (typeof message === 'string') {
        // temporary: use string partial as command dispatch
        const [action, data] = message.split(':');
        if (this[action]) { this[action](data); }
        return;
      }

      if (!message || Object.keys(message).length === 0) { return this.reset(); }

      if (message.more) { message.more = this.sanitizer.bypassSecurityTrustHtml(message.more); }
      message.id = message.translate;
      this.messages = {
        ...this.messages,
        [message.id]: message
      };

      if (message.timer) {
        setTimeout(() => this.dismissById(message.id), message.timer);
      }
    });
  }

  ngOnDestroy() {
    Logger.message.unsubscribe();
  }

  newMessage(error: any): void {
    this.messages.push(error);
  }

  dismissById(id: string) {
    if (event) { event.stopPropagation(); }
    this.messages = omit(this.messages, id);
  }

  reset(): void {
    // TODO: exclude persistent from reset?
    this.messages = [];
  }

  executeAction(action: () => void): void {
    if (typeof action === 'function') { action(); }
  }
}
