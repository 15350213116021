import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { NotFoundComponent } from './views/not-found/not-found.component';

import { AuthGuard } from '@app/services/auth/auth.guard';

const routes: Routes = [
  // public routes
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'cognito_login',
    loadChildren: () => import('./views/sso-redirect/sso-redirect.module')
      .then(m => m.SsoRedirectModule)
  },
  {
    path: 'recovery',
    loadChildren: () => import('./views/recovery/recovery.module')
      .then(m => m.RecoveryModule)
  },
  //
  // protected routes
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module')
      .then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },

  // generic routes
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
