<div class="mt-4 mx-auto" [class.dirty]="input.dirty">
  <span transloco="passwordReq1">Your password should contain at least </span>
  <span transloco="passwordReq2" class [class.match]="match('(?=.*[a-z])')">
    one character (abc)</span>,
  <span transloco="passwordReq3" class [class.match]="match('(?=.*[A-Z])')">
    an uppercase character (ABC)</span>,
  <span transloco="passwordReq4" class [class.match]="match('(?=.*[0-9])')">
    a number (123)</span>
  <span transloco="passwordReq5" class [class.match]="match('(?=.*[!@#$%^&*()\\/_+=-])')">
    and a special character (!@#)</span>.
  <span transloco="passwordReq6" class [class.match]="match('(?=.{8,})')">
    It should be at least 8 characters in length</span>.
</div>
