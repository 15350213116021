<p>
  Abartys Health is committed to protecting the privacy of members’ health information, and to complying with
  applicable federal and state laws that protect the privacy and security of a member’s health information.
</p>
<p>
  Abartys Health has adopted a number of internal safeguards to prevent the unauthorized use, alteration, or
  disclosure of PHI orally, in writing, or transferred electronically throughout the company. These safeguards
  include administrative procedures, physical protections, and technology security solutions. Abartys Health will
  continue to maintain adequate administrative, technical and physical safeguards to protect the privacy of PHI from
  unauthorized use or disclosure, whether intentional or unintentional, and from theft and unauthorized alteration.
  Safeguards are also utilized to effectively reduce the likelihood of use or disclosure of PHI that is unintended
  and incidental to a use or disclosure in accordance with Abartys Health policies and procedures.
</p>
<p>
  HIPAA compliance is an on-going process, and we are committed to continued compliance through periodic review of
  our internal policies and procedures and technological assessments as our organization grows and our technology
  evolves.
</p>

<h4><a (click)="section = section == 1 ? 0 : 1">Know our internal policies</a></h4>
<div *ngIf="section==1">
  Policies and procedures are an essential part of any organization. Together, policies and procedures provide a
  roadmap for day-to-day operations. They ensure compliance with laws and regulations, give guidance for
  decision-making and streamline internal processes.
  <br><br>
  <a href="https://abartys-public-docs.s3.amazonaws.com/compliance/Compliance_and+_Standards_Matrix.pdf" target="_blank">Compliance and standards matrix</a>
</div>

<h4><a (click)="section = section == 2 ? 0 : 2">File a complaint</a></h4>
<div *ngIf="section==2">
  Any person has the right to file a complaint if the person believes that Abartys Health has not adequately
  protected the health information entrusted to us or ensured patient rights with respect to their health
  information. To file a complaint, please complete this form in its entirety and submit online.
  <br><br>
  <a href="https://abartys-public-docs.s3.amazonaws.com/compliance/Complaint_Form.pdf" target="_blank">Data breach report</a>
</div>

<h4><a (click)="section = section == 3 ? 0 : 3">HIPAA forms and disclosures</a></h4>
<div *ngIf="section==3">
  With Abartys Health Web App Solutions (PatientLynk, InsureLynk, ProviderLynk) there is no need to fill forms any
  more. With our unique platform you are in total control of your data. You will be able to amend, share, revoke,
  delete, accounting, upload, download your PHI data as also access everywhere at any time from any device.
</div>

<h4><a (click)="section = section == 4 ? 0 : 4">Know how your data is protected and highly available</a></h4>
<div *ngIf="section==4">
  <h3>Your security is our priority</h3>
  <p>
    Ensuring your security is just as important to us as delivering a consistent, thoughtful user experience. We have
    various measures and policies in place to protect the data of all our users. We strongly believe in transparency
    when it comes to security and have outlined our practices below for your knowledge.
  </p>
  <p>
  <h5>Compliance</h5>
  Abartys Health continuously seeks to protect your data with the highest standards in the industry, which is why
  we’ve worked to achieve compliance with HIPAA among others industry standards as required.
  <p>
  <h5>We treat your data with care</h5>
  We have strict policies about who can access our servers. Abartys Health servers can only be accessed from our
  environment firewall on AWS, and only operational team members can access that environment. Our operational
  team members are restricted from accessing the servers except for authorized routine checks and further
  investigation into user feedback such as bugs. Abartys Health users have ZERO direct access to our servers.
  <p>
  <h5>We use a top-notch data center</h5>
  We use Amazon Web Services (AWS) for our data center. AWS is the most used and reliable hosting service in the
  world. Their security standards are unrivaled, and their services are designed for high-volume data center
  operations. AWS offers expert insights on operational services—they always have us and you covered. AWS has
  multiple data centers that are dispersed in remote areas around the world. Even if a data center is down due to
  failure, a recovery data center can seamlessly continue service.
  <p>
  <h5>We backup your data for peace of mind</h5>
  Abartys Health has two countermeasures in case of server failure, human error, etc.
  <ol>
    <li>The database server forwards data to another server in real-time and copies in an instant. What does this
      mean for your data? If the database server fails for any reason, it is possible to resume using Abartys
      Health with cloned data.</li>
    <li>A full backup for Abartys Health data is done once per day. What does this mean for your data? If we lose
      messages due to an operational mistake, we can rollback the messages to the previous two weeks.</li>
  </ol>
  Backup data stored in AWS is housed in an extremely durable facility(*), so the probability of data being lost due
  to a failure is almost equal to zero.
  <br><br>
  (*) AWS states its storage durability is 99.999999999%. If we store 10,000 objects with AWS, on average, we may
  lose one of them every 10 million years or so. ( document: https://aws.amazon.com/blogs/aws/new-amazon-s3-
  reduced-redundancy-storage-rrs/ )
  <p>
  <h5>We prohibit unauthorized access</h5>
  We have powerful systems in place to protect against application vulnerability and prevent malicious third
  parties from accessing your data on Abartys Health.<br>
  We will only grant access to your account after the proper Email/Access ID and password have been entered.
  When you successfully sign in to our secure websites, your browser will establish a secure connection between
  your computer and our web servers using Transport Layer Security (TLS), which helps prevent your information
  from being tampered with during transmission across the Internet.<br>
  All modern web browsers include mechanisms that indicate when online connections are secure. Often a lock
  symbol is used and may be displayed in the web browser address bar and/or elsewhere on your screen. Visit
  the website for your browser to learn about the symbols or mechanisms they use to let you know when online
  connections are secure.
  <p>
  <h5>All HTTP connections are encrypted</h5>
  Your communications and transactions are secure and confidential when you successfully sign in to our secure
  websites with an Email/Access ID and password. We use Transport Layer Security (TLS) to help keep your
  information confidential while you conduct business on our secure websites. TLS helps ensure the integrity and
  confidentiality of your online transactions by creating a uniquely encrypted transmission over the Internet
  between your web browser and Abartys Health.<br>
  For your security, you will not be able to sign in to our secure websites if your browser does not support a
  minimum of 128-bit encryption. For improved security, performance and support, we recommend that you use
  the latest browser version with all of the applicable security patches installed.
  <p>
  <h5>Authentication of your identity</h5>
  We will only grant access to your account after the proper Email/Access ID and password have been entered.
  When you successfully sign in to our secure websites, your browser will establish a secure connection between
  your computer and our web servers using Transport Layer Security (TLS), which helps prevent your information
  from being tampered with during transmission across the Internet.<br>
  All modern web browsers include mechanisms that indicate when online connections are secure. Often a lock
  symbol is used and may be displayed in the web browser address bar and/or elsewhere on your screen. Visit the
  website for your browser to learn about the symbols or mechanisms they use to let you know when online
  connections are secure.
  <p>
  <h5>Monitoring server and troubleshooting</h5>
  We monitor server reports 24/7. Our monitoring system notifies our operational team of system abnormalities.
  Our team follows up on reported abnormalities as soon as they come in.
  <p>
  <h5>You get the latest information about failure and maintenance</h5>
  We inform users of planned maintenance outages a week in advance with in-app announcements and/or in
  Abartys services status website. http://www.abartyshealth.com/status. Our Twitter account also publishes when
  Abartys Health is done with maintenance. If the problem is persistent, we will report it on our blog.<br>
  Example: https://status.nulab-inc.com/
  <p>
  <h5>Communicating with Abartys Health</h5>
  We promote safe communication practices when dealing with our clients and customers. Whether you
  communicate with us through telephone, email or online, here’s what you can expect:
  <ul>
    <li>We will not ask you to provide personal, financial or confidential information in email communications initiated by Abartys Health.</li>
    <li>We will always confirm your identity by asking a set of questions with responses that you previously supplied to us. This applies whether you contact us, or we contact you.</li>
    <li>We will never ask you to change your account information. You have to initiate changes to your account yourself.</li>
    <li>We will never ask you for your password.</li>
    <li>We protect your email address by maintaining appropriate technical safeguards. All emails we receive are screened for infected file attachments.</li>
    <li>We may require additional information or verification before accepting a transaction, to ensure the account holder is making the request.</li>
  </ul>
  Abartys Health reserves the right to restrict or cancel access to the site without providing notice. This will be
  done when Abartys Health has reason to believe that your data may be at risk.
  <p>
  <h5>Password protection</h5>
  When you access our secure websites, we need proof of your identity in the form of your Email/Access ID and
  password. We will only grant access to your account if the proper Email/Access ID and password are entered.
  Always keep your password confidential. Only you should know what it is. Learn more on how you can protect
  your password.<br>
  To prevent others from guessing your password, we will disable your password for 30 minutes after 3
  consecutive unsuccessful sign-on attempts. If account is disabled for other reasons, contact the number
  indicated on the error message received or please contact us.<br>
  Abartys Health will not be held responsible for any loss or damage which may be incurred as a result of
  unauthorized use of your password.
  <p>
  <h5>Session Time-out</h5>
  To prevent your account from remaining open when not in use, your online session with our secure websites will
  be terminated after several minutes of inactivity. If this occurs, you will need to successfully sign in again with
  your Email/Access ID and password to continue.
  <p>
  <h5>How to protect yourself</h5>
  Abartys Health takes due care in protecting your information. Here’s what you can do to help.
  <p>
  <h5>Protecting your identity</h5>
  Identity theft happens when your personal information is stolen and used illegally to charge expenses to your
  accounts, create new accounts in your name or for other illegal purposes.<br>
  Here’s what you can do to minimize identity theft:
  <ul>
    <li>Don’t provide your personal information unless you need to.</li>
    <li>Don’t respond to unsolicited requests for personal or account information.</li>
    <li>Shred documents and any junk mail containing personal information before you throw them away.</li>
    <li>Don’t leave your personal mail in an area where it can be looked at by others.</li>
    <li>Don’t include your Social Insurance Number / Social Security Number or driver’s license number on sensitive documents, unless you understand the purpose and consent to it.</li>
    <li>Keep documents such as Passports, Birth Certificates, etc., in a secure place, such as in a safety deposit box.</li>
    <li>Check your online financial accounts to watch for any suspicious transactions.</li>
    <li>Don’t keep your payment card Personal Identification Number (PIN) in your wallet.</li>
    <li>Sign the back of your credit and debit cards as soon as you receive them.</li>
    <li>Report lost or stolen credit and debit cards immediately.</li>
    <li>Review your credit report at least once a year.</li>
  </ul>
  <p>
  <h5>Protecting your password</h5>
  Your password is your proof of identity. Using a strong password helps you to protect your identity.<br>
  Keep your Abartys Health secure site sign-on information (ID and password) confidential to prevent
  unauthorized use. We recommend that you:
  <ul>
    <li>Change your password on a regular basis.</li>
    <li>Use a strong password that has at least 8 characters with a combination of uppercase and lowercase letters, numbers and special characters.</li>
    <li>Do not leave your computer unattended while connected to Abartys Health secure sites.</li>
    <li>Do not share your password with anyone.</li>
    <li>Do not use the same password twice.</li>
    <li>Do not write your password down.</li>
    <li>Do not store your password in a computer file unless it is encrypted.</li>
    <li>Do not use personal or identifying information as your password, for example, date of birth, name or Social Insurance Number / Social Security Number.</li>
  </ul>
  <p>
  <h5>Protecting your computer and mobile devices<br>Safe computing tips</h5>
  <ul>
    <li>Install anti-virus software, anti-spyware software, and a personal firewall.  Be sure to schedule periodic, (e.g., weekly) anti-virus and anti-spyware scans to run automatically.</li>
    <li>Keep all your software up to date, including your operating system, browser, etc. Regardless of what operating system you use (e.g., Windows, Mac, etc.) you should keep it up-to-date. It’s helpful to configure your operating system to automatically install new updates as they are issued by the manufacturer. To learn more, visit your manufacturer’s support site, e.g., Microsoft support, Mac support.</li>
    <li>Only download and install software from reputable websites. Avoid installing software received as email attachments from unknown sources.</li>
    <li>Only download mobile applications from an authorized provider. This is true for Abartys Health applications, which should be installed directly from the Abartys Health website or from an authorized provider, such as Google Play Store, BlackBerry App World or Apple’s App Store. There is no way of knowing what information will be gathered from an unauthorized application or where it will be sent.</li>
    <li>Secure your home wireless network by changing the default administration ID and password (using a strong password!) changing your wireless network name and, most importantly, enabling WPA2 encryption.</li>
    <li>Use a spam filter, for example the filter offered by your email provider.</li>
    <li>Never respond to spam emails as this only confirms that your email address is valid.</li>
    <li>Do not click on links in emails as you could inadvertently download malware or spyware. Enter the link web address into your browser instead.</li>
    <li>Create bookmarks for frequently used websites and use them to access the websites.</li>
    <li>Be aware of your surroundings when using a computer or mobile device in a public place. Others may see what’s on your screen.</li>
    <li>Be careful about using public Wi-Fi networks where you could potentially expose sensitive information.</li>
  </ul>
  <p>
  <h5>Data encryption</h5>
  For your security, you will not be able to sign in to our secure websites if your browser does not support a
  minimum of 128-bit encryption. For improved security, performance and support, we recommend that you use
  the latest browser version with all of the applicable security patches installed.<br>
  To ensure you are using latest browser version available, you can follow the applicable link below:
  <ul>
    <li>Microsoft Internet Explorer</li>
    <li>Google Chrome</li>
    <li>Mozilla Firefox</li>
    <li>Apple Safari</li>
  </ul>
  <p>
  <h5>Anti-virus software</h5>
  Anti-virus software can help protect your computer from malicious software (malware such as viruses).  Malware
  is the term used to describe any software that has a malicious intent including: disrupting computer operations
  and stealing information. There are many ways malware can infect your computer including:  clicking on links,
  clicking on images, downloading files (e.g., music) and opening email attachments.
  <p>
  <h5>Personal firewall</h5>
  When your computer is connected to the internet it is vulnerable to attack. Help protect your computer by using
  a personal firewall which can serve as a barrier against attacks.
  <p>
  <h5>Operating system</h5>
  Regardless of what operating system you use (e.g., Windows, Mac, etc.) you should keep it up to date. It’s helpful
  to configure your operating system to automatically install new updates as they are issued by the manufacturer.
  <p>
  <h5>Download mobile applications from authorized providers</h5>
  You should download mobile applications from an authorized provider. This is true for Abartys Health Financial
  applications, which should be installed directly from a Abartys Health Financial website or from an authorized
  provider, such as Google Play Store, BlackBerry App World or Apple’s App Store There is no way of knowing what
  information will be gathered from an unauthorized application or where it will be sent.
  <p>
  <h5>Contact us</h5>
  To report a suspicious email, website or phone call that you believe is using a Abartys Health brand, logo or
  corporate name inappropriately (or any confusingly similar brand, logo or corporate name), please contact
  us and provide as many details as you can, including your country of residence.
  <br>
  <br>
  <a href="https://abartys-public-docs.s3.amazonaws.com/compliance/AWS_HIPAA_Compliance_Whitepaper.pdf" target="_blank" rel="noopener noreferrer">
    AWS HIPAA Compliance Whitepaper
  </a><br>
  <a href="https://abartys-public-docs.s3.amazonaws.com/compliance/NIST_Implementing_HIPAA_Security_Rule.pdf" target="_blank" rel="noopener noreferrer">
    NIST – Implementing the HIPAA Security Rule
  </a><br>
  <a href="https://abartys-public-docs.s3.amazonaws.com/compliance/AWS_Fault_Tolerance_%26_High_Availabilty.pdf" target="_blank" rel="noopener noreferrer">
    AWS Fault Tolerance & High Availabilty
  </a><br>
  <a href="https://abartys-public-docs.s3.amazonaws.com/compliance/AWS_Web_Application_Hosting_Reference.pdf" target="_blank" rel="noopener noreferrer">
    AWS Web Application Hosting Reference
  </a><br>
  <a href="https://abartys-public-docs.s3.amazonaws.com/compliance/aws-web-hosting-best-practices.pdf" target="_blank" rel="noopener noreferrer">
    AWS Web Hosting Best Practices
  </a><br>
  <a href="https://abartys-public-docs.s3.amazonaws.com/compliance/AbartysHealth_Review_by_Battelle.pdf" target="_blank" rel="noopener noreferrer">
    AbartysHealth Review by Battelle
  </a><br>
</div>

