import { APP_INITIALIZER, NgModule } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {HttpClientModule} from '@angular/common/http';

import {IntroSectionComponentModule} from '@app/components/intro-section/intro-section.component';
import {persistStateInitFactory} from '@app/app-initializers';
import * as localForage from 'localforage';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NotifyBannerComponent} from './components/notify-banner/notify-banner.component';
import {AuthModule} from './services/auth.module';
import {NotFoundComponent} from './views/not-found/not-found.component';
import {LoginComponent} from './views/login/login.component';
import { NavbarModule } from '@app/navbar/navbar.module';
import { LoginFormComponentModule } from '@app/components/login-form/login-form.component';
import { SvgIconComponentModule } from '@app/components/svg-icon/svg-icon.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoRootModule } from '@app/transloco-root.module';
import { AuthChallengeComponentModule } from '@app/components/auth-challenge/auth-challenge.component';
import { preloadTranslations } from '@app/app-initializers';
import { TranslocoService } from '@ngneat/transloco';
import { ServiceWorkerModule } from '@angular/service-worker';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarModule} from '@ngx-loading-bar/core';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotifyBannerComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslocoRootModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    NavbarModule,
    AuthModule,
    LoginFormComponentModule,
    IntroSectionComponentModule, // temp
    SvgIconComponentModule,
    NgbModalModule, // TODO: make lazy
    AuthChallengeComponentModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: window.location.hostname !== 'localhost',
    }),
  ],
  providers: [
    { provide: 'persistStorage', useValue: localForage },
    { provide: APP_INITIALIZER, useFactory: persistStateInitFactory, multi: true, deps: [TranslocoService] },
    { provide: APP_INITIALIZER, useFactory: preloadTranslations, multi: true, deps: [TranslocoService] }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
