import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
// import {NavigationEnd, Router} from '@angular/router';
// import {TranslocoService} from '@ngneat/transloco';
// import { filter, take, delay } from 'rxjs/operators';
//
// import {environment} from '../environments';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    // private translate: TranslocoService,
    // private router: Router,
    // private appRef: ApplicationRef,
    // private zone: NgZone,
    // private _profile: ProfileService,
  ) {}

  ngOnInit() {
    // this.setLanguage();
    //
    // // Don't load Usersnap, Fullstory until loading is complete
    // this.appRef.isStable.pipe(
    //   take(1),
    //   delay(1000),
    //   // don't load analytics during development
    //   filter(() => !window.location.href.includes('localhost'))
    // ).subscribe(() => this.initExternal());
  }

  // setLanguage() {
  //   const [ browserLang ] = navigator?.language?.split('-');
  //   const availableLangs = this.translate.getAvailableLangs() as string[];
  //   this.translate.setDefaultLang(
  //     localStorage.getItem('lang') ||
  //     availableLangs.find(lang => lang === browserLang) || 'en'
  //   );
  // }
  //
  // registerGAPageviews() {
  //   this.router.events.pipe(
  //     filter(event => event instanceof NavigationEnd)
  //   ).subscribe((event: NavigationEnd) => {
  //     (window as any).ga('set', 'page', event.urlAfterRedirects);
  //     (window as any).ga('send', 'pageview');
  //   });
  // }
  //
  // initExternal() {
  //   this.zone.runOutsideAngular(() => {
  //     usersnapInit();
  //
  //     (async () => {
  //       const fullStory: any = await import('@fullstory/browser').catch();
  //       fullStory?.init?.({ orgId: 'VJGY2' });
  //     })();
  //
  //     (function(i, s, o, g, r, a, m) {
  //       i['GoogleAnalyticsObject'] = r;
  //       i[r] = i[r] || function() {
  //         (i[r].q = i[r].q || []).push(arguments);
  //       }, i[r].l = 1 * (new Date() as any);
  //       a = s.createElement(o),
  //         m = s.getElementsByTagName(o)[0];
  //       a.async = 1;
  //       a.src = g;
  //       m.parentNode.insertBefore(a, m);
  //     })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
  //     window['ga']('create', 'UA-123679004-2', 'auto');
  //   });
  //
  //   this.registerGAPageviews();
  // }
}
