import {Injectable} from '@angular/core';
import {persistState, Store, StoreConfig} from '@datorama/akita';
import * as localForage from 'localforage';
import { Tokens } from '@app/services/auth/token.model';

const authStore = localForage.createInstance({
  name: 'cliniclynk',
  storeName: 'auth_store'
});

persistState({ include: ['auth'], storage: authStore });

export interface AuthState {
   access: string;
   refresh: string;
   id: string;
   username: string;
   challenge?: string;
   session?: string;
}

export function createInitialState(): AuthState {
  return {
    access: '',
    refresh: '',
    id: '',
    username: ''
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'auth',
  resettable: true,
  cache: {
    ttl: 2880000 // 4h
  }
})
export class AuthStore extends Store<AuthState> {

  constructor() {
    super(createInitialState());
  }

  setAll(tokens: Tokens & { username, password }) {
    const { AuthenticationResult, ChallengeName, Session, username } = tokens;
    let newState = {};

    if (ChallengeName && Session) {
      newState = {
        challenge: ChallengeName,
        session: Session,
        username
      };
    } else if (AuthenticationResult) {
      newState = {
        access: AuthenticationResult.AccessToken,
        refresh: AuthenticationResult.RefreshToken,
        id: AuthenticationResult.IdToken,
        username,
        challenge: '',
        session: ''
      };
    }

    this.update(currentState => ({...currentState, ...newState }));
  }

  async setSSOLogin(credentials){
    let newState = {};
    newState = {
      access: credentials.AuthenticationResult.access_token,
      refresh: credentials.AuthenticationResult.refresh_token,
      id: credentials.AuthenticationResult.id_token,
      username: '',
      challenge: '',
      session: ''
    };
    this.update(currentState => ({...currentState, ...newState }));
    return newState
  }
}
