import {Injectable} from '@angular/core';
import {environment as env} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { pluck, share } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private httpClient: HttpClient) { }

  listDashboards$ = this.httpClient.get(`${env.apiUrl}/list-dashboards/`)
    .pipe(
      share(),
      pluck('data')
    );

  private API = {
    get: <T = any>(endpoint = '', options = {}, db = 'default') =>
      this.httpClient.get<T>(`${env.apiUrl}${endpoint}`, options),
    post: <T = any>(endpoint, data = {}, db = 'default', options = {}) =>
      this.httpClient.post<T>(`${env.apiUrl}${endpoint}`, data, options),
    delete: <T = any>(endpoint, data = {}, db = 'default', options = {}) =>
      this.httpClient.delete<T>(`${env.apiUrl}${endpoint}`, options),
    patch: <T = any>(endpoint, data = {}, db = 'default', options = {}) =>
      this.httpClient.patch<Partial<T>>(`${env.apiUrl}${endpoint}`, data, options),
  };

  login(params) {
    return this.httpClient.post(`${env.apiUrl}/login/`, params)
      .pipe(pluck('data'));
  }

  refresh(params) {
    return this.httpClient.post<any>(`${env.apiUrl}/refresh-token/`, params, {
      headers: { ignoreLoadingBar: '' }
    });
  }

  authChallengeResponse(params) {
    return this.httpClient.post(`${env.apiUrl}/auth-challenge-response/`, params)
      .pipe(pluck('data'));
  }

  forgotPassword(params) {
    return this.httpClient.post(`${env.apiUrl}/forgot-password/`, params);
  }

  confirmForgotPassword(params) {
    return this.httpClient.post(`${env.apiUrl}/confirm-forgot-password/`, params);
  }

  getDashboard(params) {
    return this.httpClient.post(`${env.apiUrl}/get-dashboard/`, params)
      .pipe(pluck('data'));
  }

  public mailbox(db, id) {
    return this.httpClient.get(`${env.apiUrl}/patientlynk/members/files/insurance_documents/?database=${db}&person=${id}`);
  }

  public modifiableRiskTable() {
    return this.httpClient.get(`${env.apiUrl}/patientlynk/members/hra/modifiable_risk_table/?database=default`);
  }

  public SSO = {
    getLinks: () => {
      return this.httpClient.get(`https://tb8z928s51.execute-api.us-east-1.amazonaws.com/stage/cliniclynk/get_sso_links`)
    },
    postAuth: (code) => {
      return this.httpClient.post(`https://tb8z928s51.execute-api.us-east-1.amazonaws.com/stage/cliniclynk/get_sso_links`, {
        "authorization_code": [code]
      })
    }
  }

  public mfa = {
    status: () =>{
      return this.httpClient.get(`${env.apiUrl}/mfa/`);
    },
    activate: (request) => {
      return this.httpClient.put(`${env.apiUrl}/mfa/${request.type}/request/`, request.data);
    },
    confirm: (request) => {
      return this.httpClient.put(`${env.apiUrl}/mfa/${request.type}/verify/`, request.data);
    },
    remove_mfa: () => {
      return this.httpClient.delete(`${env.apiUrl}/mfa/`);
    },
    recovery_code: () => {
      return this.httpClient.get(`${env.apiUrl}/mfa/recovery/`);
    }
  }

  public map = {
    municipalities: () => this.httpClient.get(`${env.apiUrl}/insurelynk/providers/map/municipalities/`),
    countries: () => this.httpClient.get(`${env.apiUrl}/insurelynk/providers/map/countries/`),
    subdivisions: (country) => this.httpClient.get(`${env.apiUrl}/insurelynk/providers/map/countries/subdivisions/?country=${country}`),
    officeDetail: (office, db) => this.httpClient.get(`${env.apiUrl}/insurelynk/providers/map/office_detail/${office}/?database=${db}`),
    networks: (db) => this.httpClient.get(`${env.apiUrl}/insurelynk/providers/map/networks/?insurance=${db}&database=${db}`),
    specialties: (db, networks) => this.httpClient.get(`${env.apiUrl}/insurelynk/providers/map/specialties/?insurance=${db}&networks=${networks}`),
    internal: (params) => this.httpClient.get(`${env.apiUrl}/insurelynk/providers/map/internal/`, params),
    public: (params) => this.httpClient.get(`${env.apiUrl}/insurelynk/providers/map/public/`, params),
    providerMissing: (params) => this.httpClient.post(`${env.apiUrl}/utils/palig_provider_missing/`, params),
  }

  public getTerms() {
    return this.httpClient.get(`${env.apiUrl}/patientlynk/providers/versioned_terms_and_conditions/?database=default`);
  }

  public register(data: any) {
    return this.httpClient.post(`${env.apiUrl}/patientlynk/registration/`, data);
  }

  public deleteRecord(id: string) {
    return this.httpClient.delete(`${env.apiUrl}/patientlynk/members/lab_results/${id}/delete/`);
  }

  public revealLabsObscured(data){
    return this.httpClient.post(`${env.apiUrl}/patientlynk/members/lab_results/validate_result/`, data);
  }

  public dismissLab(data) {
    return this.httpClient.post(`${env.apiUrl}/patientlynk/members/lab_results/dismiss_result/`, data);
  }

  public getSharedLabResult(key, downloadPDF: 0 | 1 = 0) {
    // separate query params for readability
    const params = `?key=${key}&pdf_response=${downloadPDF}`;

    return this.httpClient.get(`${env.apiUrl}/patientlynk/members/lab_results/obtain_shared_lab_results/${params}`,
      downloadPDF && {responseType: 'blob'}); // conditionally change response type when retrieving raw PDF
  }

  public taxonomy(){
    return this.httpClient.get(`${env.apiUrl}/insurelynk/providers/map/taxonomies/`);
  }

  public saveCard(data) {
    return this.httpClient.post(`${env.apiUrl}/patientlynk/members/health_plan/cards/?database=default`, data);
  }

  public editCard(data, id) {
    return this.httpClient.patch(`${env.apiUrl}/patientlynk/members/health_plan/cards/${id}/?database=default`, data);
  }

  public deleteCard(id) {
    return this.httpClient.delete(`${env.apiUrl}/patientlynk/members/health_plan/cards/${id}/?database=default`);
  }

  public contact(data) {
    return this.httpClient.post(`${env.apiUrl}/utils/email_contact/`, data);
  }

  public resendCode(data) {
    return this.httpClient.post( `${env.apiUrl}/patientlynk/resend_verification_code/`, data);
  }

  public checkLabsByCode(data) {
    return this.httpClient.post( `${env.apiUrl}/patientlynk/members/lab_results/validate_phone_add_record/`, data);
  }

  public sendVerificationCode(data) {
    return this.httpClient.post(`${env.apiUrl}/patientlynk_recovery/send_mfa_verification_code/`, data);
  }

  public validateRecoveryMFA(data) {
    return this.httpClient.post(`${env.apiUrl}/patientlynk_recovery/validate_mfa_verification_code/`, data);
  }

  public emailTerms(data) {
    return this.httpClient.post(`${env.apiUrl}/utils/email_patientlynk_terms/`, data);
  }

  createDependent = data => this.API.post(`/patientlynk/members/family/`, data);

  checkZip = zip => this.API.post(`/patientlynk/providers/maps/find_zipcode/`, { zip });
}
