import { ChangeDetectionStrategy, Component, NgModule, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComplianceComponentModule } from '@app/components/compliance/compliance.component';
import { SvgIconComponentModule } from '@app/components/svg-icon/svg-icon.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-intro-section',
  templateUrl: './intro-section.component.html',
  styleUrls: ['./intro-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntroSectionComponent {
  constructor(private modal: NgbModal) { }

  openModal(modalTemplate: TemplateRef<any>) {
    this.modal.open(modalTemplate, {
      size: 'lg'
    });
  }
}

@NgModule({
  imports: [
    TranslocoModule,
    SvgIconComponentModule,
    ComplianceComponentModule
  ],
  declarations: [IntroSectionComponent],
  exports: [IntroSectionComponent]
})
export class IntroSectionComponentModule {}
