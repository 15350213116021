import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-password-req',
  templateUrl: './password-req.component.html',
  styleUrls: ['./password-req.component.scss']
})
export class PasswordReqComponent implements OnInit {
  @Input() input: NgModel;

  constructor() { }

  ngOnInit() {
  }

  match(pattern: string) {
    const regex = new RegExp(pattern, 'g');
    return this.input.value && regex.test(this.input.value);
  }
}

@NgModule({
  imports: [
    FormsModule,
    TranslocoModule
  ],
  declarations: [PasswordReqComponent],
  exports: [PasswordReqComponent]
})
export class PasswordReqComponentModule {}
