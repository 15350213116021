import { Injectable, NgZone } from '@angular/core';
import {Router} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, pluck, take, tap } from 'rxjs/operators';
import {UserIdleService} from 'angular-user-idle';

import { AuthStore } from '@app/services/auth/auth.store';
import { AuthQuery } from '@app/services/auth/auth.query';
import {ApiService} from '@app/services/api/api.service';
import {Logger} from '@app/services/logger/logger.service';
import { Tokens } from '@app/services/auth/token.model';

interface LoginParams {
  username: string;
  password: string;
  mfa_code?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private idleTimerIsRunning = false;
  isLoggedIn$ = this.query.isLoggedIn$;
  state$ = this.query.state$;

  constructor(private api: ApiService, private logger: Logger, private router: Router,
              private userIdle: UserIdleService, public store: AuthStore, private query: AuthQuery,
              private zone: NgZone) {
    this.userIdle.setConfigValues({ timeout: 0, idle: 15 * 60 });
    this.startIdleTimeout();
  }

  login(loginParams: LoginParams): Observable<Tokens> {
    return this.api.login(loginParams).pipe(
      tap((token: Tokens) => token && this.store.setAll({ ...token, ...loginParams })),
      tap(() => this.startIdleTimeout())
    );
  }

  logout() {
    return of(this.doLogoutUser());
  }

  getTokens() {
    return this.query.getValue();
  }

  // TODO: move this logic to query
  // Checking for the presence of a localStorage item was definitely not enough.
  // This will hopefully prevent the unnecessary transition when returning to an
  // open window or when accessing a bookmark or history entry, as well as repeated 401s
  isLoggedIn() {
    const token = this.query.payload;
    if (!token) {
      this.store.reset();
      return false;
    }

    const timeRemaining = token?.exp * 1000 - Date.now() > 0;
    if (!timeRemaining) {
      this.doLogoutUser();
    }
    return timeRemaining;
  }

  // TODO: maybe move this logic to query?
  startIdleTimeout() {
    if (this.idleTimerIsRunning) {
      return;
    }

    this.query.isLoggedIn$.pipe(
      filter(status => status), take(1)
    ).subscribe(() => {
      this.idleTimerIsRunning = true;
      this.userIdle.startWatching();
      this.userIdle.onTimerStart().pipe(take(1))
        .subscribe(() => this.idleLogout());
    });
  }

  idleLogout() {
    this.zone.run(() => {
      if (this.isLoggedIn()) {
        Logger.alert({
          translate: 'inactivityLogout',
          type: 'warning'
        });
      }

      this.logout().subscribe();
    });
  }

  refreshToken() {
    const { id, refresh } = this.query.token;
    return this.api.refresh({ id, refresh }).pipe(
      pluck('AuthenticationResult'),
      tap(token => this.store.setAll(token)),
      catchError(() => void this.doLogoutUser())
    );
  }

  private doLogoutUser() {
    this.logger.clearBanner();
    this.store.reset();
    this.idleTimerIsRunning = false;
    localStorage.removeItem('dashboardList');
    localStorage.removeItem('dashboardCollection')
    this.router.navigate(['/login']);
  }
}
