<h5>Since this is the first time you log in, please specify a new password.</h5>
<form class="modal-body" #form="ngForm">
  <div class="m-4 position-relative">
    <input type="password" name="new_password" id="new_password" #password1="ngModel" ngModel required showPassword>
    <label>New Password</label>
  </div>
  <div class="m-4 position-relative">
    <input type="password" name="confirm_password" id="confirm_password" ngModel required showPassword>
    <label>Confirm New Password</label>
  </div>

  <app-password-req [input]="password1" class="my-4"></app-password-req>
  <div class="m-4 ab-red text-center" *ngIf="form.submitted && (form.invalid || errorMessage)">
    <span>{{ errorMessage || 'Please fill out all fields' }}</span>
  </div>
  <div class="row m-4">
    <button class="ab_button mx-auto" (click)="onSubmit(form)" [loading]="loading">Submit</button>
  </div>
</form>
