import * as localForage from 'localforage';
import * as memoryDriver from 'localforage-driver-memory';
import {selectPersistStateInit} from '@datorama/akita';
import { filter, finalize, take } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

export function persistStateInitFactory() {
  return () => {
    return new Promise<void>(async resolve => {
      await localForage.defineDriver(memoryDriver);
      localForage.config({
        name: 'patientLynk',
        version: 1.0,
        driver: [localForage.INDEXEDDB, localForage.LOCALSTORAGE, memoryDriver._driver]
      });

      await localForage.ready();
      selectPersistStateInit().subscribe(() => resolve());
    });
  };
}

export function preloadTranslations(transloco: TranslocoService) {
  return () => {
    return new Promise<void>(resolve => {
      transloco.events$.pipe(
        filter(event => event.type === 'translationLoadSuccess'),
        take(1),
        finalize(() => resolve())
      ).subscribe();
      transloco.load('en').pipe(take(1)).subscribe();
    });
  };
}
