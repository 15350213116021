import { Component, Input, NgModule, Optional } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import {ApiService} from '@app/services/api/api.service';
import { AuthService } from '@app/services/auth/auth.service';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonLoadingDirectiveModule } from '@app/directives/button-loading.directive';
import { ShowPasswordModule } from '@app/directives/show-password.directive';
import { PasswordReqComponentModule } from '@app/components/password-req/password-req.component';

@Component({
  selector: 'app-auth-challenge',
  templateUrl: './auth-challenge.component.html',
  styleUrls: ['./auth-challenge.component.scss'],
})
export class AuthChallengeComponent {
  @Input() challengeParams = null;
  errorMessage = '';
  loading = false;

  constructor(private api: ApiService, private auth: AuthService) { }

  onSubmit(form: NgForm) {
    this.errorMessage = '';

    if (form.invalid) {
      return;
    }

    if (form.value.new_password !== form.value.confirm_password) {
      this.errorMessage = 'Password confirmation does not match.';
      return;
    }

    const { username, session } = this.challengeParams;
    const { new_password } = form.value;

    this.loading = true;
    this.api.authChallengeResponse({
      username, session, new_password
    }).subscribe(
      () => {
        this.errorMessage = '';
        this.auth.login({ username, password: new_password }).pipe(
          finalize(() => this.loading = false)
        ).subscribe();
      },
      response => {
        this.errorMessage = response;
        this.loading = false;
        this.auth.logout().subscribe();
      }
    );
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ButtonLoadingDirectiveModule,
    ShowPasswordModule,
    PasswordReqComponentModule
  ],
  declarations: [AuthChallengeComponent],
  exports: [AuthChallengeComponent]
})
export class AuthChallengeComponentModule {}
