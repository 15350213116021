import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {AuthState, AuthStore} from './auth.store';
import { decodeJWT } from '@app/utils';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  // TODO: add time-sensitivity to these checks
  isLoggedIn$ = this.select().pipe(
    map(token => !!token?.access),
    distinctUntilChanged(),
  );

  state$ = this.select();

  get isLoggedIn() { return !!this.getValue().access; }
  get token() { return this.getValue(); }
  get payload() { return decodeJWT(this.getValue().access); }

  constructor(protected store: AuthStore) {
    super(store);
  }

}
