<app-intro-section></app-intro-section>

<div class="login-form">
  <img id="login_logo" class="m-auto" src="/assets/img/AH-Logos_CL-Dark.png" />

  <ng-container *ngIf="!authChallenge">
    <app-login-form></app-login-form>

    <div class="mt-4">
      <a routerLink="/recovery" transloco="forgotPassword">I forgot my password</a>

      <!-- <hr class="mt-2">

      <button class="ab_button mx-auto" (click)="getSSOlinks()">SSO Login</button> -->

      <!-- <div class="d-block d-md-none mt-5">
        <button routerLink="/registration" class="ab_button white my-3 mx-auto" transloco="signUp">Sign Up</button>
      </div> -->
    </div>
  </ng-container>

  <app-auth-challenge *ngIf="authChallenge" [challengeParams]="challengeParams"></app-auth-challenge>

  <span class="powered-by">
    <span>© Abartys Health, 2021.</span>
  </span>
</div>
