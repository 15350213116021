import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent {
  section = 0;

  constructor() { }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ComplianceComponent],
  exports: [ComplianceComponent]
})
export class ComplianceComponentModule {}
