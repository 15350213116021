import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {AuthService} from './auth/auth.service';
import {TokenInterceptor} from './token.interceptor';
import { AuthChallengeComponentModule } from '@app/components/auth-challenge/auth-challenge.component';

@NgModule({
  providers: [
    AuthGuard,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    AuthChallengeComponentModule
  ]
})
export class AuthModule { }
