import { Component, OnDestroy, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@app/services';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  authChallenge = false;
  challengeParams = {};
  authState: Subscription;

  constructor(private auth: AuthService, private router: Router, private api: ApiService) { }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
      return;
    }

    this.authState = this.auth.state$.subscribe(state => {
      this.authChallenge = !!state.challenge;

      if (state.challenge) {
        this.challengeParams = {
          username: state.username,
          session: state.session
        };
      }

      if (state.access) {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  ngOnDestroy() {
    this.authState?.unsubscribe();
  }

  getSSOlinks(){
    this.api.SSO.getLinks().subscribe((data : any) => {
      console.log("getSSOlinks", data);
      let dataLoad = data;
      window.open(dataLoad.data.cliniclynk, "_blank");
    })
  }
}
