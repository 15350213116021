import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { NavbarComponent } from './navbar.component';
import { SvgIconComponentModule } from '@app/components/svg-icon/svg-icon.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    NgbTooltipModule,
    SvgIconComponentModule,
  ],
  declarations: [NavbarComponent],
  exports: [NavbarComponent]
})
export class NavbarModule {}
