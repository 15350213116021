import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {AuthService, Logger} from '@app/services';
import { CommonModule } from '@angular/common';
import { ShowPasswordModule } from '@app/directives/show-password.directive';
import { TranslocoModule } from '@ngneat/transloco';
import { LazyNgxMaskModule } from '@app/utils/forLazyRoot';
import { ButtonLoadingDirectiveModule } from '@app/directives/button-loading.directive';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  @Input() username = '';
  mfaCode: string;
  mfaRequired = false;
  loading = false;

  constructor(private logger: Logger, private auth: AuthService, private router: Router) {}

  submit(user: NgForm) {
    this.loading = true;
    this.logger.clearBanner();
    Object.values(user.controls).forEach(item => item.markAsDirty());
    const { username, password } = user.value;

    if (!username || !password) {
      this.logger.showBanner({ translate: 'loginBlankFields', type: 'error' });
      this.loading = false;
      return;
    }

    if (user.controls.username.invalid) { // TODO: translate
      this.logger.showBanner({ translate: 'enterValidEmail', type: 'error' });
      this.loading = false;
      return;
    }

    this.auth.login({username, password, mfa_code: this.mfaCode}).subscribe(() => {
      this.loading = false;
      this.router.navigate(['/dashboard']);
    }, () => {
      this.loading = false;
      const translate = 'badLogin';
      const translateParams = '';
      const type = 'error';

      this.logger.showBanner({ translate, translateParams, type });
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    ShowPasswordModule,
    LazyNgxMaskModule,
    ButtonLoadingDirectiveModule
  ],
  declarations: [LoginFormComponent],
  exports: [LoginFormComponent]
})
export class LoginFormComponentModule {}
