<header>
  <a routerLink="/dashboard" id="logo" [class.left]="!loggedIn"></a>

  <div class="left-section">
    <ng-container *ngIf="loggedIn">
      <div id="profile" class="d-lg-none text-right ml-auto" routerLinkActive="active" tabindex="0">
        <a class="pt-2">
          <svg-icon icon="icon-profile" width="32" height="60" align="top" class="ab-primary"></svg-icon>
        </a>
        <div class="nav-bucket" style="left: unset; right: 0">
          <a transloco="logOut" (click)="onLogout()" id="logout">Log Out</a>
        </div>
      </div>
    </ng-container>
  </div>

  <div @slide class="right-section">
    <ng-container *ngIf="loggedIn">
      <div *ngIf="dashboards?.length > 1" class="d-none d-lg-block" routerLinkActive="active">
        <a class="icon-caret-down">My Dashboards</a>
        <div class="nav-bucket">
          <a *ngFor="let dashboard of dashboards"
             [routerLink]="'/dashboard/' + dashboard.id" routerLinkActive="active">{{dashboard.name}}</a>
        </div>
      </div>
      <div class="d-none d-lg-block" routerLinkActive="active">
        <a class="pt-2">
          <svg-icon icon="icon-profile" width="32" height="60" align="top" class="ab-primary"></svg-icon>
        </a>
        <div class="nav-bucket" style="left: unset; right: 0">
          <a transloco="logOut" (click)="onLogout()" id="logout">Log Out</a>
        </div>
      </div>
    </ng-container>
  </div>

</header>
