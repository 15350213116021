<h1>The healthcare analytics platform</h1>

<div class="list_items">
  <img src="/assets/img/target-80.png">
  <span><b>Identify disease trends</b> to help patients get ahead of their health conditions.</span>
  <img src="/assets/img/heart-monitor-64.png">
  <span><b>Monitor health data</b> with insights on high-risk subpopulations and gaps in care.</span>
  <img src="/assets/img/line-chart-64.png">
  <span><b>Track diseases</b> to map the occurrence, prevalence, and trends of illness.</span>
  <img src="/assets/img/coronavirus-50.png">
  <span><b>Prevent virus spread</b> by predicting the spread of illnesses ahead of time.</span>
</div>

<ng-content></ng-content>

<a class="compliance" transloco="hipaaCompliant"
      (click)="openModal(complianceModal)">We're HIPAA Compliant!</a>

<ng-template #complianceModal let-modal>
  <div class="modal-header">
    <h2>Abartys Health commitment to<br>HIPAA compliance</h2>
    <i role="button" class="close modal-close" aria-label="Close" (click)="modal.close()">
      <span class="icon-close" aria-hidden="true"></span>
    </i>
  </div>
  <div class="modal-body">
    <app-compliance></app-compliance>
  </div>
</ng-template>
