import {
  Attribute,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  HostBinding,
  Input, NgModule,
  Output,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { NgModel, PatternValidator } from '@angular/forms';

const PATTERN = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]))(?=.*\d)((?=.*[a-z]))((?=.*[A-Z])).*$/;

@Component({
  selector: 'show-password-toggle',
  template: `
    <span class="show-password" (click)="toggle.emit()">
      <i [class]="'icon-eye-' + (showPassword ? 'open' : 'closed')"></i>
    </span>
  `,
  encapsulation: ViewEncapsulation.None
})
export class ShowPasswordToggleComponent {
  @Input() showPassword = false;
  @Output() toggle = new EventEmitter();
}

@Directive({
  selector: 'input[type="password"], input[password]'
})
export class ShowPasswordDirective {
  @HostBinding('password') get password() { return true; }
  @HostBinding('pattern') get pattern() {
    return PATTERN;
  }
  @HostBinding('type') get type() {
    return this.showPassword ? 'text' : 'password';
  }
  showPassword = false;
  toggleComponent: ComponentRef<any>;

  constructor(@Attribute('showPassword') showPassword, private ngModel: NgModel,
              private vcr: ViewContainerRef, private cfr: ComponentFactoryResolver) {
    if (showPassword !== null) {
      this.insertToggle();
    }

    ngModel?.control?.setValidators(control => {
      return PATTERN.test(control.value) ? null : { pattern: true };
    });
  }

  insertToggle() {
    const componentFactory = this.cfr.resolveComponentFactory(ShowPasswordToggleComponent);
    this.toggleComponent = this.vcr.createComponent(componentFactory);
    this.toggleComponent.instance.toggle.subscribe(() => this.togglePasswordVisibility());
  }

  togglePasswordVisibility() {
    this.toggleComponent.instance.showPassword = this.showPassword = !this.showPassword;
  }
}

@NgModule({
  declarations: [ShowPasswordDirective, ShowPasswordToggleComponent],
  exports: [ShowPasswordDirective, ShowPasswordToggleComponent]
})
export class ShowPasswordModule {}
