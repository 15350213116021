import {EventEmitter, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {translate as t} from '@ngneat/transloco';
import Swal from 'sweetalert2';
import {extractServerError} from '@app/utils';

// TODO: move helper definitions elsewhere, may not be specific to this
// service and are not exported
let swal: any;
setTimeout(() => {
  swal = Swal.mixin({
    customClass: {
      confirmButton: 'ab_button filled w-25',
      cancelButton: 'ab_button w-25 ml-4'
    },
    cancelButtonText: t('cancel'),
    buttonsStyling: false
  });
});

@Injectable({
  providedIn: 'root'
})
export class Logger {
  static message: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  static alert({
    translate = '',
    translateParams = '',
    title = '',
    text = '',
    type = 'success',
    serverError = null,
    ...swalOptions
  }) {
    const parsedMessage: any = arguments[0];
    translateParams = t(translateParams);

    parsedMessage.title = title || t(translate, {param: translateParams});
    parsedMessage.icon = parsedMessage.type;
    const messageMore = t(`${translate}Text`);
    if (messageMore.trim() !== `${translate}Text`.trim()) {
      parsedMessage.text = messageMore;
    }

    if (serverError instanceof HttpErrorResponse) {
      parsedMessage.type = 'error'; // error type should be implicit in this case
      parsedMessage.title = title || t('genericServerError');
      parsedMessage.text = text || extractServerError(serverError);
    }

    return swal.fire({ ...parsedMessage, ...swalOptions });
  }

  static banner({
    translate = '',
    translateParams = null,
    message = '',
    more = '',
    type = 'warn',
    timer = 0,
    persist = false,
    close = false,
    serverError = null,
    action = null
  }): void {
    const parsedMessage: any = arguments[0];
    translateParams = t(translateParams);

    parsedMessage.message = message || t(translate, {param: translateParams});
    const messageMore = t(`${translate}More`);
    if (messageMore.trim() !== `${translate}More`.trim()) {
      parsedMessage.more = messageMore;
    }

    if (serverError instanceof HttpErrorResponse) {
      parsedMessage.type = 'error'; // error type should be implicit in this case
      parsedMessage.message = extractServerError(serverError);
    }

    Logger.message.emit(parsedMessage);
  }

  static clear(): void {
    Logger.message.emit({}); // use message emitter to clear messages
  }

  static dismissBannerById(id: string) {
    Logger.message.emit(`dismissById:${id}`);
  }

  showAlert(options) {
    return Logger.alert(options);
  }

  showBanner(options) {
    return Logger.banner(options);
  }

  clearBanner(): void {
    return Logger.clear();
  }

  dismissBannerById(id: string) {
    return Logger.dismissBannerById(id);
  }
}
