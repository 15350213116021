export function decodeJWT(jwt) {
  if (!jwt) { return null; }
  const [_, base64Url] = jwt.split('.'); // get position [1], ignore the other
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  try {
    const jsonPayload = decodeURIComponent(atob(base64).split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
    return JSON.parse(jsonPayload);
  } catch {
    return null;
  }
}
