import { AfterContentInit, Directive, ElementRef, Input, NgModule, Renderer2 } from '@angular/core';

const LOADER =
  `<div class="spinner-border" role="status" style="font-size: 16px; vertical-align: sub">
        <span class="sr-only">Loading...</span>
   </div>`;

@Directive({
  selector: 'button[loading]'
})
export class ButtonLoadingDirective {
  originalHTML: string;
  @Input() set loading(status: boolean) {
    const elem = this.el.nativeElement;

    if (!this.originalHTML) {
      this.originalHTML = this.el.nativeElement.innerHTML;
    }

    if (status) {
      this.renderer.setAttribute(elem, 'disabled', 'disabled');
      this.renderer.setProperty(elem, 'innerHTML', LOADER);
    } else {
      this.renderer.removeAttribute(elem, 'disabled');
      this.renderer.setProperty(elem, 'innerHTML', this.originalHTML);
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}
}

@NgModule({
  declarations: [ButtonLoadingDirective],
  exports: [ButtonLoadingDirective]
})
export class ButtonLoadingDirectiveModule {}
