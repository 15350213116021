<ng-container *ngFor="let message of messages | keyvalue; let i = index">
  <div @show *ngIf="message.value as message"
      class="notify {{message.type || 'warn'}}" [class.half]="halfBanner" [style.z-index]="i + 2">
      <div class="d-flex position-relative" (click)="executeAction(message.action)"
           [style]="message.action && 'cursor: pointer'">
          <div class="m-auto">
              <span [innerHTML]="message.message || message"></span>
              <span *ngIf="message.more" (click)="message.seeMore = !message.seeMore" class="seeMoreToggle">
<!--              [transloco]="message.seeMore? 'notifyLess' : 'notifyMore'"-->
              </span>
              <i *ngIf="message.close || !message.persist" class="icon-close" (click)="dismissById(message.id)"></i>
          </div>
      </div>
      <div class="seeMore" *ngIf="message.seeMore">
          <span [innerHTML]="message.more"></span>
      </div>
  </div>
</ng-container>
