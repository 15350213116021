export const environment = {
  name: 'production',
  production: true,
  apiUrl: 'https://9yj7q42z5l.execute-api.us-east-1.amazonaws.com/prod/cliniclynk',
  mapbox: {
    accessToken: 'pk.eyJ1IjoiYm9kYXJrMjE2IiwiYSI6ImNqZjE0aHg4dzB1NG0yd25udmRpZXNrd2YifQ.ntGBbpdCuh2fAFG16foPIw',
  },
  usersnap: '118545cf-0aa1-452e-bd27-745993c30ce0',
  stream: {
    APP_TOKEN: '6jek5nk4wkzx',
    APP_ID: '48580'
  },
  labinfo_email: ["service@abartyshealth.com", "info@abartyshealth.com"],
};
