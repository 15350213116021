import { Component, Input, NgModule, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'svg-icon',
  template: `<svg [style.width.px]="width" [style.height.px]="height" [style.vertical-align]="align">
    <use attr.xlink:href="assets/img/icon-sprite.svg#{{icon}}"></use>
  </svg>
  <ng-content></ng-content>`,
  styles: [`svg {
    color: inherit;
    fill: currentColor;
  }`],
  encapsulation: ViewEncapsulation.None
})
export class SvgIconComponent {
  @Input() icon: string;
  @Input() width = 16;
  @Input() height = 16;
  @Input() align = 'sub';
}

@NgModule({
  declarations: [SvgIconComponent],
  exports: [SvgIconComponent]
})
export class SvgIconComponentModule {}
