<form name="user" #user="ngForm">
  <div class="ab-red failed-attempts">
    <span class="invisible" transloco="failedAttempts"></span>
  </div>

  <div class="input-container">
    <input type="email" name="username" id="username" #username="ngModel" ngModel required email>
    <label for="username" transloco="email">First Name</label>
    <div class="input-error">
      <span *ngIf="username.invalid && username.dirty" transloco="textError"></span>
    </div>
  </div>
  <div class="input-container">
    <input type="password" showPassword name="password" id="password" #password="ngModel" ngModel required
           autocomplete="current-password">
    <label for="password" transloco="password">Password</label>
    <div class="input-error">
      <span *ngIf="password.invalid && password.dirty" transloco="passwordError"></span>
    </div>
  </div>

  <div *ngIf="mfaRequired">
    <input name="mfa_code" type="text" [(ngModel)]="mfaCode" inputmode="numeric" mask="000000">
    <label transloco="mfaCode">MFA Code<span class="asterisk">*</span></label>
  </div>

  <button class="ab_button mt-auto mx-auto" transloco="logIn" (click)="submit(user)" [loading]="loading">Log In</button>
</form>
