import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [NgSelectModule],
  exports: [NgSelectModule]
})
export class LazyNgSelectModule {}

@NgModule({
  imports: [NgxMaskModule.forRoot()],
  exports: [NgxMaskModule]
})
export class LazyNgxMaskModule {}
